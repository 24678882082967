@use "sass:math"
//LESS variable matches (should always match corresponding overrides in overrides.less)

$screenxs: 480px
$screensm: 576px
$screenmd: 768px
$screenlg: 992px
$screenxl: 1200px
$screenxxl: 1600px
$marginvalue: 12px
$branddefault: #3d5a80
$brandaccent: #767676
$brandfooter: #767676
$white: #ffffff
$midgrey: #b4b4b4
$approve: #3f9531
$reject: #ff6b00

//Internal variables

$siderwidth: 200px
$sidercollapsedwidthmobile: 40px
$logowidth: 200px
$navmenucolor: transparent
$navhovercolor: white
$footerheight: $marginvalue * 4

=shadow($shadowlevel)
	@if $shadowlevel == 1
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
	@if $shadowlevel == 2
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
	@if $shadowlevel == 3
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)
	@if $shadowlevel == 4
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)
	@if $shadowlevel == 5
		box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)
	@if $shadowlevel == left2
		box-shadow: inset 5px 0 7px -5px rgba(0, 0, 0, 0.5), 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)


@mixin scrollbars($size)
	// For Google Chrome
	&::-webkit-scrollbar
		width: $size
		height: $size

	&::-webkit-scrollbar-thumb
		background: $branddefault


	&::-webkit-scrollbar-track
		background: lighten($brandaccent, 30%)

@import "client_variables"

//Page structure

html, body, #root
	height: 100%
	width: 100%
	overflow-x: hidden

//THIS IS BAD, FIX AND REMOVE

.login_form
	
	.ts_portal_card
		flex-direction: column
		width: 100%


.ts_portal_card
	position: relative
	max-width: 100%
	max-height: 100%
	display: flex
	
	.ant-card-body
		position: relative
		height: auto
		width: auto
		display: block
		overflow: hidden
		display: flex
		flex-direction: column

.ts_content_center
	display: flex
	align-items: start
	justify-content: center
	height: 100%
	overflow: hidden
	width: 100%
	position: relative
	padding: 10px

	.ts_content_container
		right: 0

.ts_table_filters_container
	margin-bottom: math.div($marginvalue, 1)

.ts_portal_card_maxheight
	height: 100%

.ts_table_container
	width: auto
	height: auto
	overflow-x: hidden
	overflow-y: hidden
	display: relative
	
	.ant-table-body
		max-height: 100%
		
	.ant-table
		height: 100%
		max-width: 1800px
		
	.ant-table-container
		max-height: calc(100% - (75px))

	.ant-table-container, .ant-table-wrapper, .ant-spin-nested-loading, .ant-table
		height: 100%
		position: relative
		
	.ant-spin-container
		height: calc(100% - (50px))
		position: relative
	
.ts_content
	overflow-x: hidden !important

//THIS IS BAD, FIX AND REMOVE

.ant-layout
	height: 100%
	width: 100%

.nonheader_wrapper
	display: flex
	flex-direction: column
	height: 100%
	width: 100%
	overflow-y: auto

.ts_content
	padding: $marginvalue * 2
	padding-bottom: 0
	flex-shrink: 0
	overflow-x: auto

.ts_full_width
	width: 100%
	
.invoice_uploadButton
	margin-top: 4px
	width: 100%

.missing_invoice_section
	border: 1px solid lighten($branddefault,40%)
	border-radius: 4px
	padding: $marginvalue
		
.ts-loading-shading-mui
	background: rgba(250,250,250,.8)
	
	.ant-spin-show-text
		width: 100%
		text-align: center
		font-weight: bold
		padding-top: 12px

.ts_my_account_card	
	margin-left: $marginvalue !important

//Header

.header_logo
	display: block
	width: 100px
	max-height: 48px
	height: 48px
	background-image: url(/assets/images/default/logo.svg)
	background-repeat: no-repeat
	background-position: center center
	background-size: contain

.sider_logo
	display: block
	position: absolute
	bottom: 0
	width: 100%
	max-height: $siderwidth
	height: $siderwidth
	background-image: url(/assets/images/default/logo.svg)
	background-repeat: no-repeat
	background-position: bottom center
	background-size: contain
	margin-bottom: $marginvalue * 2
	border: math.div($marginvalue, 2) solid rgba(0,0,0,0)


.ant-layout-header
	+shadow(1)


.ts_header
	padding-left: 24px
	color: $white
	background-color: $branddefault
	
	.ts_nav_collapse_icon
		font-size: 1.8em
		padding-top: 12px
		margin-right: 28px

	.ts_header_title
		color: $white
		margin-left: 30px
		margin-top: 2px

	.ts_header_row
		height: 48px
		flex-wrap: nowrap
		
	.ant-col, .ts_header_text
		max-height: 48px
		
	.ts_header_text
		width: 100%
		padding-bottom: 4px
		padding-left: 12px
		
		a, span
			display: inline
			
.ts_table_filters_row
	overflow: auto
	display: flex
	flex-wrap: wrap
	justify-content: flex-start
	
	> *
		margin-right: 10px
		margin-bottom: 5px
	
.ts_table_filters_row_search
	max-width: 300px
	display: flex
	align-items: center
	
.ts_table_filters_row_search_checkbox
	margin-left: 10px

.ts_sider_container
	@include scrollbars(7px)
	background-color: darken($branddefault, 20%) !important
	max-height: 100vh
	overflow: auto

.ts_button_brand
	background-color: $branddefault !important
	border-color: darken($branddefault,0%) !important
	transition: all 0.2s
	
	&:hover
		background-color: saturate(lighten($branddefault,5%),10%) !important
		border-color: saturate(lighten($branddefault,5%),10%) !important

.ts_button_accent
	background-color: $brandaccent !important
	border-color: darken($brandaccent,0%) !important
	transition: all 0.2s
	
	&:hover
		background-color: saturate(lighten($brandaccent,3%),10%) !important
		border-color: saturate(lighten($brandaccent,3%),10%) !important

.nav_menu
	background-color: darken($branddefault, 20%) !important

	.ant-menu-item, .ant-menu-submenu
		background-color: $navmenucolor !important

.ant-menu-dark .ant-menu-inline.ant-menu-sub
	background: darken($branddefault, 22%)


//App-specific

.ts_portal_card
	+shadow(2)

.ts_home_card
	border-top: 2px solid $branddefault
	background-color: $white
	padding: 16px
	margin: 6px
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.05)
		min-height: 150px

.ts_address_card
	border-top: 2px solid $branddefault
	background-color: $white
	padding: 16px
	margin: 16px
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.05)
	min-height: 150px

.ts_home_content_container
	max-width: 1300px
	margin: 0 auto

	.ant-menu-vertical
		border-right: 0px solid white
		
		li:hover
			background-color: #ededed


.ts_home_menu
	border-right: none
	
	ul:after
		display: none
	
.login_form
	max-width: 400px

.login_form_forgot
	float: right

.login_alert
	margin-bottom: 16px

.ts_margin_bottom
	margin-bottom: 16px

.ts_margin_left
	margin-left: 16px

.ts_form_margin
	margin-top: 16px

.ts_form_container
	width: 60%

.ts_pdf_modal
	width: 95% !important
	height: 95vh !important

	> .ant-modal-content
		height: 100%

		> .ant-modal-body
			height: calc(100% - 130px)

.ts_pdf
	width: 100%
	height: 100%

.ts_card_dark_border
	border-color: $midgrey

.ts_pre_wrap
	white-space: pre-wrap


.ts_approvals_button_margin
	margin-right: $marginvalue


.ts_approvals_approve_button
	background-color: $approve
	border-color: $approve

	&:focus
		background-color: $approve
		border-color: $approve

.ts_approvals_reject_button
	background-color: $reject
	border-color: $reject

	&:focus
		background-color: $reject
		border-color: $reject

.ts_approvals_comments_button
	font-size: 2em

.ts_approvals_comments_button_active
	color: $branddefault


.ts_content_container
	margin: 8px 24px
	right: 5px
	width: max-content
	
.ts_invoice_container
	width: auto


//Footer

.ant-layout-footer
	padding-top: 0
	padding-bottom: 0
	min-height: $footerheight
	display: flex
	justify-content: center
	align-items: center

.ts_footer
	background: $brandfooter
	color: $white
	+shadow(3)
	margin: 0px

.ts_padding
	padding: 4px

.ts_margin_left
	margin-left: 8px

.ts_icon_danger
	color: red

.ts_icon_success
	color: $approve

.ts_icon_large
	font-size: 2em

.ts_user_management_group_permissions_buttons
	margin-left: 425px

	button
		margin-right: 16px
		margin-top: 8px

.ts_table_header_row
	background-color: lighten($midgrey,27%)

.ts_approval_detail_table
	width: 100%

	tr
		border-bottom: 1px solid lighten($midgrey,17%)

	td, th
		padding: 12px
		text-align: left


.ts_notification_container
	margin-bottom: 12px

.ts_notification_popover
	margin-bottom: 12px
	max-height: 300px
	height: 300px
	overflow: auto
	width: 500px

//Media Queries
	

@media screen and (max-width: $screenmd)

	.ts_content
		padding: math.div($marginvalue, 6)
		
	.ts_portal_card
		left: 0

	.ant-layout-header
		padding: 0 ($marginvalue * 2)

	.ant-layout-sider-collapsed 
		width: $sidercollapsedwidthmobile !important
		max-width: $sidercollapsedwidthmobile !important
		min-width: $sidercollapsedwidthmobile !important
		flex: 0 0 $sidercollapsedwidthmobile !important

	.ts_header
		.ant-row
			flex-wrap: nowrap
			
		.ts_header_title_container
			display: flex
			align-items: center
			width: 400px

		.ts_header_title
			font-size: 1.5em
			line-height: .9em
			margin-bottom: $marginvalue
			padding-left: $marginvalue
			min-width: 100%

	.ts_content_container
		margin: 0 !important
		min-width: 100% !important
	
	.ts_my_account_card	
		margin-left: $marginvalue !important

	.ant-card-body
		padding: math.div($marginvalue, 1) !important

	.ant-card-head

		padding-left: $marginvalue
		padding-right: $marginvalue

//IMPORTANT: These are overrides, so leave this at the bottom

@import "client"