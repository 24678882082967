@if $mode==TS

	.header_logo
		width: 100px
		max-height: 48px
		height: 48px
		background-image: url(/assets/images/default/logo.svg)
		
	.nav_menu
		background-color: lighten($branddefault, 20%) !important
		
	.ts_sider_container
		@include scrollbars(7px)
		background-color: lighten($branddefault, 20%) !important
		
	.sider_logo
		margin-bottom: $marginvalue * 1
		border: 0 solid rgba(0,0,0,0) !important
		
@if $mode==GENERIC
	.header_logo
		width: 0
		max-height: 48px
		height: 48px
		background-image: none
		
	.ts_header_title
		color: $white
		margin-left: 30px !important
		
	.sider_logo
		background-image: url(/assets/images/default/sap_white.png)
		border: ($marginvalue * 1.5) solid rgba(0,0,0,0)
		
	.ts_header .ts_header_title
		margin-left: 0 !important
		
	.ant-menu-dark .ant-menu-inline.ant-menu-sub
		background: darken($branddefault, 25%)
	
@if $mode==ALLPRO
	.header_logo
		width: 100px
		max-height: 46px
		height: 46px
		background-image: url(/assets/images/client/APlogotype.png)
		
	.ant-layout-header
		color: white !important
		
	.ts_header_title
		color: $white
		margin-left: 30px !important
		
	.sider_logo
		background-image: url(/assets/images/client/APwhite.png)
		margin-bottom: $marginvalue * 1.5
		border: math.div($marginvalue, 2) solid rgba(0,0,0,0)
		
	.ts_sider_container
		@include scrollbars(7px)
		background-color: darken($branddefault, 14%) !important
		
	.nav_menu
		background-color: darken($branddefault, 14%) !important
		
	@media screen and (max-width: $screenmd)
		.ts_header_title
			max-width: 80px
			margin-left: 6px !important
			margin-bottom: 4px !important
		
		.ts_header_text
			font-size: .75em
			line-height: 1.25em
			
		.ts_mobilehide
			display: inherit
			
	@media screen and (max-width: $screensm)
		.ts_header_text
			font-size: .75em
			line-height: 1.25em
			display: block
			padding-left: 5px !important
			
		.ts_mobilehide
			display: none !important
		
@if $mode==BB
	.header_logo
		width: 100px
		max-height: 46px
		height: 46px
		background-image: url(/assets/images/client/bb-logo.png)
		margin-top: 3px
		
	.ts_header_title
		color: $white
		margin-left: 30px !important
		
	.sider_logo
		background-image: url(/assets/images/client/bb-icon.png)
		margin-bottom: $marginvalue * 1.5
		border: math.div($marginvalue, 2) solid rgba(0,0,0,0)
		
	.ts_sider_container
		@include scrollbars(7px)
		background-color: darken($branddefault, 14%) !important
		
	.nav_menu
		background-color: darken($branddefault, 14%) !important
		
	